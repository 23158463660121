import { useNavigate } from "react-router-dom"
import { getPrizeWinnerState } from "../../../../helpers/claimState"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getKeywordState } from "../../../../helpers/keywords/keywordState"
import { getSessionClient } from "../../../../api"
import { emailRegex } from "../../../../helpers/regexes"
import { defaultStorageHelper } from "@spry/campaign-client"
import Footer from "../../../../Components/Footer"

type GuestStatus = {
    prizeWinnerKey: string
    email: string
    claimed: boolean
}

let resendOrder: string[] = []

export default function Claimed() {
    const { t } = useTranslation()
    const keyword = getKeywordState()!
    const { prizeWinnerKey, isGuest, prizeKey } = getPrizeWinnerState() || {}
    const pickuplocation = defaultStorageHelper.get("pickup")
    const navigate = useNavigate()

    const [guestStatus, setGuestStatus] = useState<GuestStatus[]>()
    const [refreshCount, setRefreshCount] = useState(0)

    useEffect(() => {
        if (!prizeWinnerKey) { navigate("/") }
    }, [prizeWinnerKey, navigate])

    const guestCount = keyword?.settings.prizeConfig?.[prizeKey || ""]?.config?.guestCount || 0

    useEffect(() => {
        if (!prizeWinnerKey || isGuest) { return }
        if (!guestCount) { return }
        async function loadGuestStatus() {
            const result = await getSessionClient()
                .call<{
                    guests: GuestStatus[]
                } | { error: string }>("guestStatus", { prizeWinnerKey })
            if ("error" in result) { return }
            setGuestStatus(result.guests)
        }
        loadGuestStatus()
    }, [prizeWinnerKey, isGuest, setGuestStatus, refreshCount, guestCount])


    function Resend(props: { guestStatus: GuestStatus, number: number }) {
        const [email, setEmail] = useState(props.guestStatus.email)
        const [submitting, setSubmitting] = useState(false)
        const [submitted, setSubmitted] = useState(false)
        const isDuplicate = !!guestStatus?.find(x => x.prizeWinnerKey !== props.guestStatus.prizeWinnerKey && x.email.toLowerCase() === email.toLowerCase())
        const isValid = emailRegex.test(email)

        async function resend() {
            if (!isValid || submitting || isDuplicate || submitted) { return }
            setSubmitting(true)
            try {
                const result = await getSessionClient()
                    .call<{ guestPrizeWinnerKey: string } | { error: string }>("addGuest", { prizeWinnerKey, guestPrizeWinnerKey: props.guestStatus.prizeWinnerKey, email })
                setRefreshCount(refreshCount + 1)
                if ("error" in result) { return }
                setSubmitted(true)
                if (result.guestPrizeWinnerKey !== props.guestStatus.prizeWinnerKey) {
                    for (let x = 0; x < resendOrder.length; ++x) {
                        if (resendOrder[x] === props.guestStatus.prizeWinnerKey) {
                            resendOrder[x] = result.guestPrizeWinnerKey
                            break
                        }
                    }
                }
            }
            finally {
                setSubmitting(false)
            }
        }

        if (submitted) {
            return <>{email}</>
        }

        return <div style={{ marginTop: "2em" }}>
            <label className="form__label">{t("prizeClaimed.6")} #{props.number}</label>
            <input value={email} onChange={e => setEmail(e.target.value)} className={`field ${isValid ? "" : "error"}`} /><br />
            <button className="btn form__btn" disabled={submitting || !isValid || isDuplicate} onClick={resend}>{t("prizeClaimed.5")}</button>
        </div>
    }

    function NewGuest() {
        const [email, setEmail] = useState("")
        const [submitting, setSubmitting] = useState(false)
        const isDuplicate = !!guestStatus?.find(x => x.email.toLowerCase() === email.toLowerCase())
        const isValid = emailRegex.test(email)

        async function send() {
            if (!isValid || submitting || isDuplicate) { return }
            setSubmitting(true)
            try {
                const result = await getSessionClient()
                    .call<{ guestPrizeWinnerKey: string } | { error: string }>("addGuest", { prizeWinnerKey, email })
                setRefreshCount(refreshCount + 1)
                if ("error" in result) { return }
                setEmail("")                
            }
            finally {
                setSubmitting(false)
            }
        }

        return <>
            <label className="form__label">{t("prizeClaimed.6")}</label>
            <input value={email} onChange={e => setEmail(e.target.value)} className={`field ${isValid ? "" : "error"}`} /><br />
            <button className="btn form__btn" disabled={submitting || !isValid || isDuplicate} onClick={send}>{t("prizeClaimed.7")}</button>
        </>
    }

    function ResendAll() {
        const unclaimed = guestStatus?.filter(x => !x.claimed) || []
        const resends: any[] = []
        let count = (guestStatus?.filter(x => x.claimed)?.length || 0) + 1
        for (const key of resendOrder) {
            const status = unclaimed.find(x => x.prizeWinnerKey === key)
            if (status) {
                resends.push(<div key={status.prizeWinnerKey}><Resend guestStatus={status} number={count++} /></div>)
            }
        }
        for (const status of unclaimed) {
            if (resendOrder.indexOf(status.prizeWinnerKey) === -1) {
                resends.push(<div key={status.prizeWinnerKey}><Resend guestStatus={status} number={count++} /></div>)
                resendOrder.push(status.prizeWinnerKey)
            }
        }
        return <>{resends}</>
    }

    return <>
        <section className="section-confirmation section-confirmation--adjust" id="content">
            <div className="shell">
                <div className="section__inner">
                    <div className="section__check">
                        <span style={{ fontSize: "3em" }}>✔</span>
                    </div>{/* /.section__check */}

                    <h5>{t("prizeClaimed.2")}</h5>

                    {pickuplocation ? <><p>{t("prizeClaimed.3")}</p><p style={{ fontWeight: "bold" }}> {t("prizeClaimed.8")} {pickuplocation}</p></> :
                        <p >{t("prizeClaimed.3")} {t("prizeClaimed.3i")}</p>}

                    <div className="section__actions">
                        <a href="https://www.diageo.com" className="btn btn--white section__btn">{t("prizeClaimed.4")}</a>
                    </div>{/* /.section__actions */}

                    {guestStatus && guestCount && <div style={{ marginTop: "2em" }}>
                        <h5>{t("prizeClaimed.9")}</h5>
                        <p>{guestCount} {t("prizeClaimed.10")}</p>
                        {guestStatus.filter(x => x.claimed).map(x => <div key={x.prizeWinnerKey} style={{
                            display: "grid",
                            gridTemplateColumns: "3em 1fr",
                            alignItems: "center"
                        }}>
                            <span style={{ justifySelf: "center" }}>✔</span>
                            <span>{x.email}</span>
                        </div>)}
                        {<ResendAll />}
                        {guestStatus.length < guestCount && <div style={{ marginTop: "2em" }}>
                            <NewGuest />
                        </div>}
                    </div>}
                </div>{/* /.section__inner */}
            </div>{/* /.shell */}
        </section>
        <Footer />
    </>
}